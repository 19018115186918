import React from "react"
import Container from "../components/container"
import Layout from "../components/layout"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
const Style404 = styled.div`
  padding: 60px 0px;
  text-align: center;
  p {
    margin: 20px 0px 40px 0px;
  }
  h1 {
    margin: 0px;
  }
  .gatsby-image-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
`
export const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query querynotfound {
      notfound: file(relativePath: { eq: "pre_404.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <Layout>
      <Container>
        <Style404>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <GatsbyImage image={data.notfound.childImageSharp.gatsbyImageData} />
        </Style404>
      </Container>
    </Layout>
  )
}
export default NotFoundPage
